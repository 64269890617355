
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import PageAnalytics from '../../../atomic/organisms/Page-Analytics/Page-Analyitics.vue';
import Table from '../../../atomic/organisms/Table/Table.vue';
import NavTabs from '../../../atomic/atoms/Nav-Tabs/Nav-Tabs.vue';
import { Tabs } from '../../../utilities/Types/navigation.types';
import PageControls from '../../../atomic/organisms/Page-Controls/Page-Controls.vue';
import { ColumnDefinition, SortDefinition } from '../../../utilities/Types/table.types';
import { IDropdownOption } from '../../../utilities/Interfaces/form.interfaces';
import { checkPermission } from '../../../utilities/Permissions/permission-utillty';
import { AccessTypes } from '../../../enums/AccessTypes';
import axios from 'axios';

export default defineComponent({
  components: {
    'arc-page-analytics': PageAnalytics,
    'arc-table': Table,
    'arc-nav-tabs': NavTabs,
    'arc-page-controls': PageControls,
  },
  async mounted() {
    await this.resetPaginationState();
    if (this.user) {
      const response = await this.fetchOpportunitiesReports();
      this.total = response?.total;
    }
  },
  watch: {
    loadingOpportunitiesReport: {
      handler() {
        this.loading = this.loadingOpportunitiesReport;
      },
    },
    user: {
      async handler() {
        const response = await this.fetchOpportunitiesReports();
        this.total = response?.total;
      },
    },
    pageValue: {
      deep: true,
      async handler() {
        await this.updatePaginationPage(this.pageValue.value as number);
        if (!this.pageReset) {
          const response = await this.fetchOpportunitiesReports();
          this.total = response?.total;
        }
        this.pageReset = false;
      },
    },
    showValue: {
      deep: true,
      async handler() {
        await this.updatePaginationLimit(this.showValue.value as number);
        const response = await this.fetchOpportunitiesReports();
        this.total = response?.total;
      },
    },
    searchFilter: {
      deep: true,
      async handler() {
        await this.updatePaginationSearch(this.searchFilter);
        const response = await this.fetchOpportunitiesReports();
        this.total = response?.total;
      },
    },
    sortDefinition: {
      deep: true,
      async handler() {
        await this.updatePaginationSortKey(this.sortDefinition.column);
        await this.updatePaginationSortDirection(this.sortDefinition.direction);
        const response = await this.fetchOpportunitiesReports();
        this.total = response?.total;
      },
    },
    showClosedStores: {
      deep: true,
      async handler() {
        await this.updatePaginationClosedStores(this.showClosedStores);
        const response = await this.fetchOpportunitiesReports();
        this.total = response?.total;
      },
    },
  },
  computed: {
    ...mapGetters(['user', 'access', 'permissions', 'opportunitiesReport', 'loadingOpportunitiesReport']),
    columnDefinitions(): ColumnDefinition[] {
      let columns: any;
      if (this.access?.opportunitiesAll || this.access?.superAdminAccess) {
        columns = [
          {
            type: 'text',
            columnName: 'id',
            displayName: 'Mall ID',
            sortable: true,
          },
          {
            type: 'text',
            columnName: 'ownerDesc',
            displayName: 'Owner',
          },
          {
            type: 'text',
            columnName: 'description',
            displayName: 'Mall',
            headerStyle: 'min-width: 140px',
          },
          {
            type: 'text',
            columnName: 'statusDesc',
            displayName: 'Status',
          },
          {
            type: 'stringArray',
            columnName: 'brandList',
            displayName: 'Brands',
          },
          {
            type: 'text',
            columnName: 'numberOpportunitiesReceived',
            displayName: 'Number of Opportunities Received',
            position: 'center',
          },
          {
            type: 'date',
            columnName: 'lastOpportunityReceivedDate',
            displayName: 'Last Opportunity Received',
            position: 'center',
          },
          {
            type: 'text',
            columnName: 'localContactName',
            displayName: 'Local Contact Name',
            headerStyle: 'min-width: 125px',
          },
          {
            type: 'text',
            columnName: 'localContactEmail',
            displayName: 'Local Contact Email',
            headerStyle: 'min-width: 125px',
          },
          {
            type: 'stringArray',
            columnName: 'contactList',
            displayName: 'Other Contacts',
          },
        ];
      } else {
        columns = [
          {
            type: 'text',
            columnName: 'mallOwnerDesc',
            displayName: 'Owner',
          },
          {
            type: 'text',
            columnName: 'mallName',
            displayName: 'Mall',
            headerStyle: 'min-width: 140px',
          },
          {
            type: 'text',
            columnName: 'storeNumber',
            displayName: 'Store Number',
            sortable: true,
          },
          {
            type: 'text',
            columnName: 'statusDesc',
            displayName: 'Status',
          },
          {
            type: 'text',
            columnName: 'brandDesc',
            displayName: 'Brand',
          },
          {
            type: 'text',
            columnName: 'numberOpportunitiesReceived',
            displayName: 'Number of Opportunities Received',
            position: 'center',
          },
          {
            type: 'date',
            columnName: 'lastOpportunityReceivedDate',
            displayName: 'Last Opportunity Received',
            position: 'center',
          },
          {
            type: 'text',
            columnName: 'localContactName',
            displayName: 'Local Contact Name',
            headerStyle: 'min-width: 125px',
          },
          {
            type: 'text',
            columnName: 'localContactEmail',
            displayName: 'Local Contact Email',
            headerStyle: 'min-width: 125px',
          },
          {
            type: 'stringArray',
            columnName: 'contactList',
            displayName: 'Other Contacts',
          },
        ];
      }
      return columns;
    },
    tableData(): unknown {
      return this.opportunitiesReport;
    },
    tableOptions(): any {
      return {
        clickable: false,
      };
    },
    tabs(): Tabs {
      const t: any[] = [];
      if (this.access && !this.access?.marketingCampaignsNone) {
        t.push({
          title: 'OUTGOING MARKETING CAMPAIGNS',
          route: 'marketing-campaigns',
          activeRouteCheck: '/reports/marketing-campaigns',
        });
      }
      if (this.access && !this.access?.opportunitiesNone) {
        t.push({
          title: 'INCOMING OPPORTUNITIES',
          route: 'opportunities',
          activeRouteCheck: '/reports/opportunities',
        });
      }
      if (this.access && !this.access?.recruitmentCampaignsNone) {
        t.push({
          title: 'RECRUITMENT CAMPAIGNS',
          route: 'recruitment-campaigns',
          activeRouteCheck: '/reports/recruitment-campaigns',
        });
      }
      if (this.access && !this.access?.mallMemosNone) {
        t.push({
          title: 'MALL MEMOS',
          route: 'mall-memos',
          activeRouteCheck: '/reports/mall-memos',
        });
      }
      if (this.access && !this.access?.storeMemosNone) {
        t.push({
          title: 'STORE MEMOS',
          route: 'store-memos',
          activeRouteCheck: '/reports/store-memos',
        });
      }
      if (this.access?.marketingCampaignsNone) {
        t.push({
          title: 'MARKETING CAMPAIGNS',
          route: 'marketing-campaigns',
          activeRouteCheck: '/reports/marketing-campaigns',
        });
      }
      if (this.access?.recruitmentCampaignsNone) {
        t.push({
          title: 'RECRUITMENT CAMPAIGNS',
          route: 'recruitment-campaigns',
          activeRouteCheck: '/reports/recruitment-campaigns',
        });
      }
      if (this.access?.mallMemosNone) {
        t.push({
          title: 'MALL MEMOS',
          route: 'mall-memos',
          activeRouteCheck: '/reports/mall-memos',
        });
      }
      if (this.access?.storeMemosNone) {
        t.push({
          title: 'STORE MEMOS',
          route: 'store-memos',
          activeRouteCheck: '/reports/store-memos',
        });
      }
      if (this.access?.marketingCampaignsBrand) {
        t.push({
          title: 'OVERALL MARKETING CAMPAIGN APPROVALS',
          route: 'overall-marketing-campaign-approvals',
          activeRouteCheck: '/reports/overall-marketing-campaign-approvals',
        });
      }
      return t;
    },
    pageOptions(): IDropdownOption[] {
      let total: any = this.total;
      let showValue: any = this.showValue.value;
      let options = [];
      let numberOfPages = Math.ceil(total / showValue);
      for (let i = 0; i < numberOfPages; i++) {
        options.push({ description: `${i + 1}`, value: i + 1 });
      }
      return options;
    },
    totalPages(): number {
      let total: any = this.total;
      let showValue: any = this.showValue.value;
      return Math.ceil(total / showValue);
    },
  },

  data(): {
    searchFilter: string;
    showValue: IDropdownOption;
    showOptions: IDropdownOption[];
    pageValue: IDropdownOption;
    pageReset: boolean;
    allTableData: any;
    sortDefinition: SortDefinition;
    loading: boolean;
    total: number;
    buttonFeedback: {
      exportLoading: boolean;
      exportSubmitted: boolean;
    };
    showClosedStores: boolean;
    hideMC: boolean;
    hideRC: boolean;
    hideMM: boolean;
    hideSM: boolean;
  } {
    return {
      searchFilter: '',
      showValue: { description: '25', value: 25 },
      showOptions: [
        { description: '10', value: 10 },
        { description: '25', value: 25 },
        { description: '50', value: 50 },
        { description: '100', value: 100 },
      ],
      pageValue: { description: '1', value: 1 },
      pageReset: false,
      allTableData: [],
      sortDefinition: { column: 'id', type: 'text', direction: 'DESC' },
      loading: true,
      total: 0,
      buttonFeedback: {
        exportLoading: false,
        exportSubmitted: false,
      },
      showClosedStores: false,
      hideMC: true,
      hideRC: true,
      hideMM: true,
      hideSM: true,
    };
  },

  methods: {
    ...mapActions([
      'openModal',
      'updateModalType',
      'updateModalComponent',
      'updateModalData',
      'fetchOpportunitiesReports',
      'updatePaginationPage',
      'updatePaginationLimit',
      'updatePaginationSearch',
      'updatePaginationSortKey',
      'updatePaginationSortDirection',
      'updatePaginationClosedStores',
      'resetPaginationState',
    ]),
    startLoading(): void {
      this.loading = true;
    },
    finishLoading(): void {
      this.loading = false;
    },
    resetPageValue(): void {
      this.pageReset = true;
      this.pageValue = { description: '1', value: 1 };
    },
    updateAllTableData(data: any): void {
      this.allTableData = data;
    },
    async checkAccess() {
      this.hideMC = await checkPermission('access', this.user, AccessTypes['marketingCampaignsNone']);
      this.hideRC = await checkPermission('access', this.user, AccessTypes['recruitmentCampaignsNone']);
      this.hideMM = await checkPermission('access', this.user, AccessTypes['mallMemosNone']);
      this.hideSM = await checkPermission('access', this.user, AccessTypes['storeMemosNone']);
    },
    async sort(payload: SortDefinition) {
      this.resetPageValue();
      this.sortDefinition = payload;
    },
    async updateShow(filter: IDropdownOption) {
      this.resetPageValue();
      this.showValue = filter;
    },
    async updatePage(filter: IDropdownOption) {
      this.pageValue = filter;
    },
    async previousPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value - 1}`,
        value: pageValue.value - 1,
      };
    },
    async nextPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value + 1}`,
        value: pageValue.value + 1,
      };
    },
    async search(searchFilter: string): Promise<void> {
      this.resetPageValue();
      this.searchFilter = searchFilter;
    },
    async export(): Promise<void> {
      this.buttonFeedback.exportSubmitted = true;
      this.buttonFeedback.exportLoading = false;
      const URL = `${process.env.VUE_APP_URI}/exports/opportunities-report`;
      this.$notify({
        title: 'Export is in progress.',
        text: `You will receive an email with the required export file attached once it is ready. You can continue working in the app.`,
        type: 'info',
        duration: 12000,
      });
      setTimeout(() => {
        this.buttonFeedback.exportSubmitted = false;
      }, 12000);
      await axios.get(URL, {
        withCredentials: true,
      });
    },
  },
});
